* {
  margin: 0;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
} */

@font-face {
  font-family: "Frutiger";
  src: url(assets/fonts/Frutiger\ LT\ Arabic/frutiger-lt-arabic-55-roman.ttf);
}
.Frutiger {
  font-family: Frutiger;
  font-optical-sizing: auto;
  font-style: normal;
}
body a {
  text-decoration: none;
  background-color: "text.main";
}

body {
  height: 100vh;
  font-family: "Arial";
  color: white;
}

.container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
}

.content {
  display: flex;
  gap: 5em;
  width: 100%;
  padding-top: 3em;
  position: relative;
}

.content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid white;
  transform: scaleX(1);
}

h1 {
  font-size: 4rem;
  width: 50vw;
  line-height: 97%;
  text-align: right;
}

section {
  height: 100vh;
  color: white;
}

@keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}
@-webkit-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}

@-moz-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}

@-webkit-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}

 
 
 

 

@media screen and (max-width: 1280px) {
  .chart-container {
    padding-right: 0;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.chart-container {
  padding-right: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
 
.chart-box {
  width: 50%;
  margin: 0 auto;
}
@media screen and (max-width: 600px) {
 
  .chart-box {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
::-webkit-scrollbar {
  display: none;
}
.sideBar::-webkit-scrollbar {
  display: none;
}

 

.apexcharts-legend-text {
  color: lightgray !important;
}
.form-control {
  width: 200% !important;
  border-radius: 14px;
  height: 50px !important;
  padding: 10px;
  background-color: transparent;
  color: gray;
  border: 2px solid lightgray;
  min-width: auto;
  font-size: 17px;
}
.form-control-holiday {
  width: 100% !important;
  border-radius: 14px;
  height: 50px !important;
  padding: 10px;
  background-color: transparent;
  color: gray;
  border: 2px solid lightgray;
  min-width: auto;
  font-size: 17px;
}
.form-control-update {
  width: 100% !important;
  border-radius: 14px;
  height: 50px !important;
  padding: 10px;
  background-color: transparent;
  color: gray;
  border: 2px solid lightgray;
  min-width: auto;
  font-size: 17px;
}
@media (max-width: 1500px) {
  .form-control {
  width: 150% !important;
  }
}
@media (max-width: 1300px) {
  .form-control {
  width: 150% !important;

  }
}
@media (max-width: 1200px) {
  .form-control {
  width: 100% !important;
  

  }
}
@media (max-width: 600px) {
  .form-control {
  width: 100% !important;

  }
}
